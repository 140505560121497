import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";

const PolicyCompanyModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        position="absolute"
        top="0"
        m="0"
        borderRadius="0"
        maxHeight="100vh"
        maxWidth="90vw"
      >
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <div style={{ marginTop: 15 }}>
            <p>
              <strong>기업회원 개인정보 수집 동의&nbsp;</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>[개인정보보호정책]</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                개인정보라 함은 생존하는 개인을 식별하거나 식별할 수 있는 일체의
                정보를 말하며, 당해 정보만으로는 개인을 식별할 수 없을지라도
                다른 정보와 용이하게 결합하여 개인을 식별할 수 있는 것을
                포함한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>1. 개인정보의 수집범위</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                뷰티벅스(Beautibucks)은 별도의 회원가입 절차 없이 대부분의
                컨텐츠에 자유롭게 접근할 수 있습니다. 뷰티벅스의 회원제 서비스를
                이용하시고자 할 경우 다음의 정보를 입력해 주셔야 하며 선택항목을
                입력하시지 않았다 하여 서비스 이용에 제한은 없습니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 회원가입시 수집하는 개인정보의 범위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                : 이름, 희망 ID, 희망 비밀 번호, 전화번호(유선 또는 휴대폰),
                이메일주소, 이메일 및 SMS 수신여부
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 유료 정보 이용시 수집하는 개인정보의 범위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                * 신용카드 결제: 카드사명, 카드번호 등
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                * 은행계좌 이체: 은행명, 계좌번호 등
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                * 휴대전화 결제: 가입자명, 이동전화번호, 결제승인번호 등
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                3) 구인정보 등록시 수집하는 개인정보의 범위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>: 주소/사진(선택사항)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                4) 구직정보 등록시 수집하는 개인정보의 범위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                : 사진(선택사항), 결혼, 자녀, 국적, 나이, 학력/경력(선택사항)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                5) 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이
                자동으로 생성되어 수집될 수 있습니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                - IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2. 개인정보의 수집목적 및 이용목적
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                뷰티벅스에 제공해 주신 귀하의 정보는 일차적으로는 이용자
                구분이나 구인/구직정보에 필요한 정보로 이용되며 원활한
                구인/구직을 위해 (주)에프엘씨굿컴퍼니 유료직업소개소의 구인/구직
                정보활용에 이용됩니다. 이차적으로는 통계분석을 통한 고용 및
                취업동향 파악 및 고용정책, 마케팅 자료로서 구인자와 구직자를
                위한 적합한 서비스를 제공하는데 이용됩니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                또한 채용회원은 채용을 희망하는 지역의 구직정보를 웹사이트 또는
                휴대폰 SMS문자서비스를 통해 유상 또는 무상으로 제공받습니다.
                (단, 채용자가 회원가입 시 문자수신 부분에 동의시 통지)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 성명, 주민등록번호, 아이디, 비밀 번호 : 회원제 서비스 이용에
                따른 본인 식별 절차에 이용
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 이메일주소, 전화번호, 이메일 및 SMS 수신여부 : 고지사항 전달,
                본인의사 확인, 불만처리 등 원활한 의사소통에 이용
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                3) 신용카드정보, 은행계좌정보 : 유료정보 용에 대한 요금 결제 및
                환불에 이용
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                4) 주소, 사진, 결혼, 자녀, 국적, 나이, 학력/경력(선택사항), 근무
                희망지역, 희망 업직종 : 구인/구직 정보 제공에 이용
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                3. 개인정보의 이용기간 및 보유기간
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                귀하의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은
                목적이 달성되면 파기됩니다. 단, 상법 등 관련법령의 규정에 의하여
                다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간
                보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                - 회원가입정보의 경우, 회원 탈퇴하거나 회원에서 제명된 경우 등
                일정한 사전에 보유목적, 기간 및 보유하는 개인정보 항목을
                명시하여 동의를 구합니다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                - 계약 또는 청약철회 등에 관한 기록 : 5년
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                - 대금결제 및 재화 등의 공급에 관한 기록 : 5년
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                귀하의 동의하에 보유하고 있는 거래정보 등을 귀하께서 열람을
                요구하는 경우 뷰티벅스는 지체없이 그 열람.확인 할 수 있도록
                조치합니다.
              </span>
            </p>
            <p>&nbsp;</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            닫기
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PolicyCompanyModal;
