import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
const TermsOUseCompanylModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        position="absolute"
        top="0"
        m="0"
        borderRadius="0"
        maxHeight="100vh"
        maxWidth="90vw"
      >
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <div style={{ marginTop: 15 }}>
            <p>
              <strong>기업회원 이용약관</strong>
              <strong>&nbsp;</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>[약 관]</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 1장 총칙</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 1 조 (목적)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                본 약관은 (주)애프엘씨굿컴퍼니(이하 "회사")이 운영하는 뷰티벅스
                서비스 (이하 "서비스")를 제공함에 있어 회사와 이를 이용하는
                가입자 (이하 "회원)의 권리, 의무 및 책임사항을 규정함을 목적으로
                한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 2 조 (용어의 정의)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                이 약관에서 사용하는 용어의 정의는 다음과 같다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① "서비스"라 함은 인터넷 주소를 통해 기업 또는 개인이 구인,
                구직을 목적으로 등록하는 자료를 DB화 하여 각각의 목적에 맞게
                분류 가공, 집계된 구인구직정보를 웹사이트, 모바일, 휴대폰
                문자통지 등의 방법으로 구인구직자에게 제공하는 서비스 및
                부대서비스를 말한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② "회원"이라 함은 서비스를 이용하기 위하여 본 약관에 동의하고
                회사와 이용계약을 체결하여 이용자 ID를 부여 받은 개인 또는
                기업을 말한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ③ "회원 ID"라 함은 회원의 식별과 회원의 서비스 이용을 위하여
                회원이 선정하고 회사가 부여하는 문자와 숫자의 조합을 말한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ④ "비밀 번호"라 함은 회사의 서비스를 이용하려는 사람이 이용자
                ID를 부여 받은 자와 동일인임을 확인하고 회원의 권익을 보호하기
                위하여 회원이 선정한 문자와 숫자의 조합을 말한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑤ "사이트"라 함은 회사가 서비스를 "회원"에게 제공하기 위하여
                컴퓨터 등 정보통신 설비를 이용하여 설정한 가상의 영업장 또는
                회사가 운영하는 웹사이트, 모바일웹, 앱 등의 서비스를 제공하는
                모든 매체를 통칭 해당 서비스를 제공받을 수 있는 아래의 사이트를
                말한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>- 뷰티벅스 : www.</span>{" "}
              <span style={{ fontWeight: 400 }}>beautiBucks.com</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>- 뷰티벅스 모바일 : m.</span>{" "}
              <span style={{ fontWeight: 400 }}>beautiBucks.com</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 3 조 (약관의 명시와 개정)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명,
                사업자등록번호, 연락처 등을 이용자가 알 수 있도록 초기 화면에
                게시하거나 기타의 방법으로 이용자에게 공지해야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회사는 약관의 규제 등에 관한 법률, 전기통신기본법,
                전기통신사업법, 정보통신망 이용 촉진등에 관한 법률 등 관련법을
                위배하지 않는 범위에서 이 약관을 개정할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ③ 회사가 약관을 개정할 경우에는 적용일자 및 개정 사유를 상당한
                기간 동안 해당 사이트에 공시하여야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ④ 회원은 변경된 약관에 대해 거부할 권리가 있다. 회원은 변경된
                약관이 공지된 지 15일 이내에 거부 의사를 표명할 수 있다. 회원이
                거부하는 경우 본 서비스 제공자인 회사는 15일의 기간을 정하여
                회원에게 사전 통지 후 당해 회원과의 계약을 해지할 수 있다. 만약,
                회원이 거부 의사를 표시하지 않고 서비스를 계속 이용하는 경우에는
                동의하는 것으로 간주한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑤ 이 약관에서 규정하지 않은 사항에 관해서는 약관의 규제 등에
                관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용
                촉진등에 관한 법률 등의 관계 법령에 따른다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 2 장 서비스 이용 계약</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 4 조 (이용계약의 성립)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사의 서비스 이용계약(이하 '이용계약'이라 한다)은 서비스를
                이용하고자 하는 자의 본 약관과 개인정보보호정책의 내용에 대한
                동의 및 이용신청(회원가입신청)에 대하여 회사가 승낙함으로써
                성립한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 서비스를 이용하고자 하는 자가 이용신청 당시 웹상에서 "동의함"
                버튼을 누르면 본 약관 및 개인정보보호정책에 대하여 동의한 것으로
                간주한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ③ 제1항의 승낙은 이용신청자의 본 약관에 대한 동의를 확인하고
                웹상에서 통지함으로써 이루어지고 이러한 승낙의 통지와 함께
                이용계약이 성립한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 5 호 (이용신청)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회원으로 가입하여 본 서비스를 이용하고자 하는 이용고객은
                회사에서 요청하는 제반정보(이름, 연락처(휴대전화번호) 등)를
                제공하여야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 모든 회원은 반드시 회원 본인의 이름과 연락처(휴대전화번호)를
                제공하여야만 서비스를 이용할 수 있으며, 실명으로 등록하지 않은
                사용자는 일체의 권리를 주장할 수 없다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ③ 타인의 명의를 도용하여 이용신청을 한 회원의 모든 ID는
                삭제되며, 관계법령에 따라 처벌을 받을 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 6 조 (이용신청의 승낙과 제한)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사는 전조의 규정에 의한 이용신청 고객에 대하여 업무 수행상
                또는 기술상 지장이 없는 경우에는 원칙적으로 접수순서에 따라
                서비스 이용을 승낙한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회사는 아래사항에 해당하는 경우에 대해서는 이용신청을 승낙하지
                아니한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 실명이 아니거나 타인의 명의를 이용하여 신청한 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 이용계약 신청서의 내용을 허위로 기재한 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                3) 만 14세 미만의 자가 개인회원으로 이용신청을 한 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                4) 이용계약이 강제로 해지된 회원이 다시 이용신청을 하는 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                5) 기타 위법한 이용신청임이 확인된 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ③ 회사는 아래사항에 해당하는 경우에는 그 신청에 대하여 승낙제한
                사유가 해소될 때까지 승낙을 유보할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 회사가 설비의 여유가 없는 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 회사의 기술상 지장이 있는 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                3) 기타 회사의 귀책사유로 이용승낙이 곤란한 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 7 조 (회원의 권리 의무)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 이용자가 제4조 제3항에 의한 회사의 승낙을 받는 즉시 회사와
                이용자 간에 이용계약이 체결되고 이용자는 회원이 된다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회원은 본 약관 및 관계법령에 따라 권리 및 의무를 가진다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 8 조 (이용계약의 종료)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회원은 언제든지 회사에 해지의사를 표시함으로써 일부 또는
                전체사이트와의 이용계약을 종료할 수 있다. 해지의사표시는 회사가
                정한 방식에 따라 표시되어야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회원이 동조 제1항에 의하여 탈퇴하는 경우, 탈퇴의사 통지 전에
                회사의 인터넷서비스와 관련된 모든 절차를 완료, 철회 또는
                취소하여야 한다. 이 경우 취소, 철회로 인한 불이익은 전적으로
                회원 본인의 부담한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ③ 회원이 동조 제1항에 의한 탈퇴의사를 표시하는 경우, 회사는
                회원의 서비스 이용상태를 확인한 후 탈퇴신청을 처리한다. 단,
                탈퇴의사 통지전 유료서비스 이용요금은 모두 완납하셔야 하며, 이를
                해태하고 탈퇴를 요청할 경우, 회사는 요금 완납시까지 탈퇴처리를
                보류할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ④ 회사는 다음 각호에 해당하는 경우 회원과의 이용계약을 해지할 수
                있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 비실명으로 회원등록을 한 사실이 확인된 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 회원의 나이가 만 14세 미만으로 확인된 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                3) 회원가입신청시 기재사항이 허위로 드러난 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                4) 관계법령 및 동 약관의 의무사항을 위반한 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                5) 허위광고, 음란한 광고 등 불량광고를 등록한 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                6) 유료서비스 이용 후 그 이용요금을 납부하지 않은 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                7) 서비스 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의가
                발생한 때
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                8) 기타 본 서비스 명예를 훼손하거나 회원으로서의 자격을
                지속시키는 것이 부적절하다고 판단되는 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑤ 회사가 동조 제4항에 의하여 회원과의 이용계약을 해지하는 경우,
                그 해지의사는 이메일, 전화, 팩스, 기타 회원이 회사에 등록한
                연락처로 통지할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑥ 회사는 본조 제5항의 해지의사 통지발송 전, 회원에게 당 해지
                사유에 대한 의견진술의 기회를 부여할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑦ 이용계약의 종료는 다음의 경우 그 효력을 발생한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 동조 제1항에 의한 경우 - 해지의사의 통지가 회사에 도달한 시점
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 동조 제4항에 의한 경우 - 해지의사의 통지가 회원에게 발송된
                시점
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑧ 이용계약이 해지된 경우, 회사는 당해 회원의 개인정보를
                파기한다. 단, 유료서비스 이용과 관련된 정보는 관계 법령에 따라
                법령이 정한 일정한 기간 동안 정보를 보관한다. 이때, 보관하는
                정보는 보관 목적 이외의 용도로 이용하지 아니한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 3 장 서비스 제공 및 이용
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 9 조 (서비스의 내용)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사가 제공하는 서비스 내용은 다음과 같다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 구인회원에게는 구직정보를 구직회원에게는 구인정보를 무상 또는
                유상으로 제공한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 구인/구직정보 제공 시 회원의 종류별 차등 제공할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                3) "회사"는 구직자의 활발한 구직활동을 위하여 "회사"에서
                제공하는 타 사이트에 동시 게재가 가능하도록 할 수 있으며, 사이트
                항목은 제2조 ⑤항의 항목에서 열람할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>② 정보의 제공방법</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 구인/구직정보는 웹사이트, 모바일, 휴대폰 SMS 통지의 방법으로
                회원에게 제공한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 휴대폰 문자통지의 방법으로 서비스를 제공할 경우에는 회원가입
                시 문자 수신, 문자 발송에 동의한 회원에게 적용된다. 회사는 문자
                수신, 발송에 동의한 회원이라도 변경을 원하는 회원을 위하여
                회원계정의 마이페이지에 문자 수신, 발송에 대한 동의/거부를
                선택할 수 있도록 조치하고, 인터넷 이용이 불편한 회원은
                고객센터를 통하여 이를 적극 반영토록한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 10 조 (서비스의 종류 및 요금)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사가 제공하는 서비스는 무료를 원칙으로 한다. 다만 자신의
                구인/구직정보를 보다 효과적으로 노출시키기 위한 별도의 서비스나
                특정정보를 제공받기 위한 서비스는 유료로 제공될 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회사는 유료서비스를 제공할 경우 사이트에 요금에 대해서 공지를
                하여야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ③ 회사는 유료서비스 이용금액을 서비스의 종류 및 기간에 따라
                회사가 예고 없이 변경할 수 있다. 다만, 변경 이전에 적용 또는
                계약한 금액은 소급하여 적용하지 아니한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 11 조 (서비스의 개시)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                서비스는 회사가 이용신청을 승낙한 때로부터 즉시 개시된다. 다만,
                회사의 업무상 또는 기술상의 장애로 인하여 서비스를 즉시 개시하지
                못하는 경우에는 그러하지 아니하며, 이런 경우에는 회사는 회원에게
                그 사실을 지체 없이 통지한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 12 조 (서비스 이용시간 및 제한)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 서비스는 회사의 업무상 또는 기술상 장애, 기타 특별한 사유가
                없는 한 연중무휴, 1일 24시간 이용할 수 있다. 단, 다음의 경우에는
                서비스 이용의 전부 또는 일부를 제한할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                (1) 설비의 점검 등 회사가 필요한 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                (2) 설비의 장애, 서비스 이용의 폭주 등 불가항력 사항으로 인하여
                서비스 이용에 지장이 있는 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                (3) 기타 회사가 필요하다고 판단되는 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사는 제공하는 서비스 중 일부에 대한 서비스 이용시간을 별도로
                정할 수 있다. 이 경우 그 이용시간을 사전에 회원에게 공지 또는
                통지한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회사의 사유로 인해 이용시간이 제한될 경우 회사는 기간의 정함이
                있는 유료서비스 이용자들에게는 그 이용기간을 연장하는 등의
                방법으로 손실을 보상하여야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 13 조 (제휴를 통한 서비스)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사는 제휴 관계를 체결한 여타 인터넷 웹 사이트 및 신문, 잡지
                등의 오프라인 매체를 통해 사이트에 등록한 회원의 구인/구직정보를
                열람될 수 있도록 서비스를 제공할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회사는 제휴를 통해 타 사이트 및 매체에 등록될 수 있음을
                고지해야 하며, 제휴 사이트 전체 목록을 사이트내에서 상시 열람할
                수 있도록 해야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 14 조 (정보의 제공 및 광고의 게재)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사는 서비스를 운용함에 있어서 각종 정보를 서비스에 게재하는
                방법 등으로 회원에게 제공할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회사는 서비스의 운용과 관련하여 서비스 화면, 홈페이지, 휴대폰
                등에 광고 등을 게재할 수 있으며 회원들의 전자우편 주소와
                휴대폰으로 광고메일과 SMS 문자 메시지를 보낼 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 15 조 (이용대금의 결제)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회원은 유료서비스의 이용대금의 지급방법으로 회사가 제공하는
                지급방법 중 하나를 선택할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회원이 대금지급을 위하여 회사가 정한 결제방법 중 하나를 선택한
                경우 회원은 결제의 이행을 위하여 필요한 개인정보를 정확하게
                입력하여야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ③ 회사는 회원이 결제에 필요한 개인정보를 허위 또는 부정확하게
                입력함으로 인하여 회원에게 발생한 손해에 대해 책임을 부담하지
                않는다. 단, 회사의 고의 또는 중과실이 있는 경우에는 그러하지
                아니한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 16 조 (이용대금의 환불)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1. 컨텐츠의 특성상 유료서비스가 시작된 경우, 회사는 거래의 취소
                및 이용료를 환불하지 아니한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>① 유료서비스</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 채용공고가 위치별 광고로 정상적으로 노출된 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 인재서칭서비스를 이용한 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 다음과 같은 경우 회사는 거래취소 및 이용료를 환불한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 유료결제 후 전산상의 오류로 광고가 누락된 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 이중과금일 경우(과다납입)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 17 조 (서비스 제공의 중지)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사는 다음 각 호의 1에 해당하는 경우 서비스의 제공을 중지할
                수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 시스템등 설비의 보수점검, 교체가 있는 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 시스템등 설비가 고장난 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                3) 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를
                중지하는 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                4) 기타 불가항력적인 사유가 발생한 경우
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 본조 제1항 단서의 경우, 회사는 회원 또는 제3자가 입은 손해에
                대해서 배상하지 아니한다. 단, 회사의 고의 또는 중과실이 있는
                경우에는 그러하지 아니한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 4 장 회사, 회원의 권리&middot;의무
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 18 조 (회사의 의무)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사는 제12조 및 제17조에서 정한 경우를 제외하고 이 약관에서
                정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록
                최선의 노력을 다하여야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회사는 서비스에 관련된 설비를 항상 운용할 수 있는 상태로
                유지&middot;보수하고, 장애가 발생하는 경우 지체없이 이를
                수리&middot;복구할 수 있도록 최선의 노력을 다하여야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ③ 회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를
                즉시 처리하여야 하며, 즉시 처리가 곤란한 경우 그 사유와
                처리일정을 서비스 또는 전자우편를 통하여 동 회원에게 통지하여야
                한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ④ 회사는 유료 결제와 관련한 결제 사항 정보를 1년 이상 보존한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑤ 천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가
                발생하여 서비스가 중단될 경우 이에 대한 손해에 대해서는 회사가
                책임을 지지 않는다. 다만 자료의 복구나 정상적인 서비스 지원이
                되도록 최선을 다할 의무를 진다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑥ 회원의 자료를 본 서비스 이외의 목적으로 제3자에게 제공하거나
                열람시킬 경우 반드시 회원의 동의를 얻어야 한다. 다만 관계법령에
                의하여 수사상의 목적으로 관계기관으로부터 요구 받은 경우에는
                회원의 개인정보가 공개될 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 19 조 (회원의 의무)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회원은 관계법령, 이 약관의 규정, 이용안내 및 주의사항 등
                회사가 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에
                방해되는 행위를 하여서는 아니된다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회원은 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이
                복사, 복제, 변경, 번역, 출판&middot;방송 기타의 방법으로
                사용하거나 이를 타인에게 제공할 수 없다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ③ 회원은 서비스 이용과 관련하여 알게 된 다른 회원의 정보를
                부당하게 이용할 수 없다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ④ 회원은 이용신청서의 기재내용 중 변경된 내용이 있는 경우
                서비스를 통하여 그 내용을 회사에 통지하여야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑤ 회원은 서비스의 이용권한등 이용계약상의 지위를 타인에게 양도,
                증여할 수 없으며, 이를 담보로 제공할 수 없다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑥ 회원이 신청한 유료서비스는 등록 또는 신청과 동시에 회사와
                채권, 채무 관계가 발생하며, 회원은 이에 대한 요금을 지정한 기일
                내에 납부하여야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑦ 회원이 결제 수단으로 신용카드를 사용할 경우 비밀 번호 등 정보
                유실 방지는 회원 스스로 관리해야 한다. 단, 사이트의 결함에 따른
                정보유실의 발생에 대한 책임은 회원의 의무에 해당하지 아니한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑧ 회원은 본 서비스를 건전한 구인 구직 이외의 목적으로 사용해서는
                안되며 이용 중 다음 각 호의 행위를 해서는 안 된다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 다른 회원의 아이디(ID)를 부정사용하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 아이디를 공유할 목적으로 공모(n/1), 대여, 양도, 정보 무단
                배포 등에 관한 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                3) 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                4) 선량한 풍속, 기타 사회질서를 해하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                5) 타인의 명예를 훼손하거나 모욕하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                6) 타인의 지적재산권 등의 권리를 침해하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                7) 해킹행위 또는 컴퓨터 바이러스의 유포행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                8) 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로
                전송하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                9) 서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의
                행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                10) 운영하는 사이트에 회원을 가입시킬 목적으로 구인/구직정보를
                수집, 스크랩, 본인사이트에 임의로 게시하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                11) 회원을 모집할 목적으로 구인광고를 등록하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                12) 기타 관계법령에 위배되는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 20 조 (게시물에 대한 권리와 의무)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사가 작성한 게시물에 대한 저작권을 포함한 모든 권리 및
                책임은 회사에게 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회원이 등록한 게시물에 대한 저작권을 포함한 권리 및 책임은
                이를 게시한 회원에게 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ③ 회원은 자신이 창작, 등록한 게시물의 무상의 비독점적 사용권을
                회사에 부여하며, 이 사용권은 회사가 서비스를 운영하는 동안 계속
                유효하다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ④ 회원은 자료 내용 및 게시물을 사실에 근거하여 성실하게 작성해야
                하며, 만일 자료의 내용이 사실이 아니거나 부정확하게 작성되어
                발생하는 모든 책임은 회원에게 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑤ 모든 게시물의 관리와 작성은 회원 본인이 하는 것이 원칙이나
                사정상 위탁 또는 대행관리를 하더라도 자료내용의 책임은 회원에게
                있으며 회원은 주기적으로 자신의 자료를 확인하여 항상 정확하게
                관리가 되도록 노력해야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑥ 회사는 회원이 등록한 자료 내용에 오자, 탈자, 잘못된 사진(본인
                인물사진이 아닌 경우) 또는 사회적 통념에 어긋나는 문구가 있을
                경우 이를 언제든지 수정할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑦ 회사는 구인/구직회원의 게시물을 보다 빠른 구인/구직을 위해
                게시판 최상단으로 임의갱신하여 회원에게 노출시킬 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ⑧ 회사는 안정적인 서비스를 제공하기 위해 테스트 및 모니터링,
                고객문의 용도에 한하여 회원의 채용/인재정보를 열람할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 21 조 (금지행위)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회원은 서비스 이용 및 게시물 등록과 관련하여 다음 각 호의
                행위를 하여서는 아니된다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                1) 다른 회원의 아이디(ID)를 부정사용하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                2) 아이디를 공유할 목적으로 공모(n/1), 대여, 양도, 정보 무단
                배포 등에 관한 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                3) 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                4) 선량한 풍속, 기타 사회질서를 해하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                5) 타인의 명예를 훼손하거나 모욕하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                6) 타인의 지적재산권 등의 권리를 침해하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                7) 해킹행위 또는 컴퓨터 바이러스의 유포행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                8) 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로
                전송하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                9) 서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의
                행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                10) 운영하는 사이트에 회원을 가입시킬 목적으로 구인/구직정보를
                수집, 스크랩, 본인사이트에 임의로 게시하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                11) 회원을 모집할 목적으로 구인광고를 등록하는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                12) 기타 관계법령에 위배되는 행위
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 본조 제1항 각호의 행위가 적발된 경우, 회사는 회원에게
                사전통지나 동의 없이 게시물을 삭제할 수 있으며, 제8조에 의거하여
                회원과의 이용계약을 해지하거나, 해당 회원의 서비스 이용자격을
                정지할 수 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 5 장 기타</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                제 22 조 (개인정보의 보호)
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                회사는 이용자의 개인정보보호를 위하여 노력하여야 한다. 이용자의
                개인정보에 관해서는 정보통신망이용 촉진 및
                정보보호등에관한법률에 따르고, 사이트에 "개인정보보보호정책"을
                고지한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 23 조 (면책)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사는 무료로 제공되는 서비스와 관련하여 회원에게 발생한
                손해에 대해서 책임을 지지 아니한다. 단, 그 손해가 회사의 중대한
                과실에 의한 경우는 그러하지 아니한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 정확성,
                신뢰성 등 그 내용에 관하여는 어떠한 책임을 부담하지 아니한다.
                회원은 자기의 책임아래 서비스를 이용하며, 서비스를 이용하여 게시
                또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사선택,
                기타 서비스 이용과 관련하여 어떠한 불이익이 발생하더라도 이에
                대한 모든 책임은 회원에게 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ③ 회사는 회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여
                책임을 지지 아니한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ④ 회원 아이디(ID)와 비밀 번호의 관리 및 이용상의 부주의로 인하여
                발생되는 손해 또는 제3자에 의한 부정사용 등에 대한 책임은
                회원에게 있다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 24 조 (손해배상)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회원이 이 약관의 규정을 위반함으로 인하여 회사가 회원 또는
                제3자에 대하여 손해에 대하여 책임을 부담하게 되는 경우, 동
                회원은 회사에 발생된 모든 손해를 배상하여야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 본 약관 제21조 회원의 금지사항 9항, 10항을 위반하였을 경우,
                개인정보유출로 인한 회원의 피해와 회사의 영업손실은 회원이
                지불한 정보이용료로 보상되지 아니한다. 이를 위반하여 사용하는
                회원을 회사가 적발하였을 경우, 1일 70만원(1일광고비 70만원,
                1일임금비 30만원, 기타관리비10만원등을 감안한 1일 손실액)을
                위반일수에 계상하여 회원은 회사에 배상하기로 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>제 25 조 (분쟁의 해결)</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ① 회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게
                해결하기 위하여 필요한 모든 노력을 하여야 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 전항의 노력에도 불구하고, 동 분쟁에 관한 소송은 회사의 주소지
                관할법원으로 한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                ② 본조의 분쟁에 관하여는 한국법을 적용한다.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                (주)애프엘씨굿컴퍼니 (사이트명: 뷰티벅스{" "}
              </span>
              <a href="http://www.hairinjob.com">
                <span style={{ fontWeight: 400 }}>
                  http://www.beautiBucks.com
                </span>
              </a>
              <span style={{ fontWeight: 400 }}>)</span>
            </p>
            <p>
              <br />
              <br />
              <br />
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            닫기
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default TermsOUseCompanylModal;
